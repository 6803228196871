<template>
    <section class="content-wrapper">
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-3">
                                <!-- media screen > 992px -->
                                <div class="sidebarCategories">
                                    <div class="mt-4">
                                        <h5>Catégories</h5>
                                        <div class="form-check form-check-primary">
                                            <label class="form-check-label">
                                            <input type="checkbox" class="form-check-input" >
                                            Toutes les catégories
                                            <i class="input-helper"></i>
                                            </label>
                                        </div>
                                        <div class="form-check form-check-primary" v-for="(category, i) in $store.state.categories" :key="i">
                                            <label class="form-check-label">
                                            <input type="checkbox" class="form-check-input" >
                                            {{category.category}}
                                            <i class="input-helper"></i>
                                            </label>
                                        </div>
                                    </div>
                                    <!-- TAGS -->
                                    <!-- <div class="border-bottom py-4">
                                        <h5>Tags</h5>
                                        <div>
                                            <label class="badge badge-outline-dark">Chalk</label>
                                            <label class="badge badge-outline-dark">Hand lettering</label>
                                            <label class="badge badge-outline-dark">Information Design</label>
                                            <label class="badge badge-outline-dark">Graphic Design</label>
                                            <label class="badge badge-outline-dark">Web Design</label>
                                        </div>
                                    </div> -->
                                </div>
                                <!-- media screen < 922px -->
                                <div class="sidebarCategories2">
                                    <div role="group" class="input-group">
                                        <input type="text" placeholder="Recherche" class="form-control" id="__BVID__226">
                                        <div class="input-group-append">
                                            <div class="input-group-text bg-primary text-white">
                                            <span>
                                                <i class="fa fa-search"></i>
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="navbar-nav-scroll mt-4">
                                        <ul class="navbar-nav bd-navbar-nav flex-row">
                                            <li class="nav-item" v-for="(category, y) in $store.state.categories" :key="y">
                                                <a class="nav-link ml-2"><label data-v-5f1c8a7a="" class="badge badge-outline-dark">{{category.category}}</label></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-9">
                                <h4 class="mt-4 mb-4">{{categoryName}}</h4>
                                <div class="row product-item-wrapper">
                                    <div v-if="products.length <1">
                                        <h3>Il n'y a pas de produit </h3>
                                    </div>
                                    <div v-else class="col-lg-6 col-md-6 col-sm-6 col-12 product-item stretch-card" v-for="(product,producId) in products" :key="producId">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="action-holder">
                                                <div class="sale-badge bg-gradient-success">New</div>
                                                <span class="favorite-button"><i class="mdi mdi-heart-outline"></i></span>
                                                </div>
                                                <div class="product-img-outer">
                                                <img class="product_image" src="@/assets/images/import/actu-1.jpg" alt="prduct image">
                                                </div>
                                                <p class="product-title" style="cursor:pointer" @click="showDetails(product)">{{product.name}}</p>
                                                <p class="product-price">{{product.price}} €</p>
                                                <p class="product-description">Ceci est la description du produit</p>
                                                <p class="text-muted">{{product.datepublished}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
const axios = require('axios').default
export default {
    name:'shop',
    data () {
        return {
            categoryName:'',
            products:'',
            ulidCategory:''
        }
    },
    computed: {
        // filteredCategory () {
        // }
    },
    watch: {
        ulidCategory(value) {
            console.log('watcher category');
            console.log(value)
        }
    },
    methods: {
        showDetails(product) {
            const ulid= product.ulid
            this.$router.push({
                name:'product',
                params: { ulid }
            })
        },
        getProductCategory(ulid) {
        axios.get(`products/categories/${ulid}`)
        .then(resProduct=> {
            this.products= resProduct.data.result;
            // console.log('products', this.products)
        })
        .catch(errProduct=> console.log('error Get categories product',errProduct))
        }
    },
    beforeRouteUpdate(to,from,next) {
        // console.log('reusing this component')
        // console.log('enter to', to.params.ulidCategory)
        this.getProductCategory(to.params.ulidCategory)
        this.categoryName= to.params.categoryName
        next()
    },
    mounted () {
        console.log('paramètre dans mounted', this.$route.params.ulidCategory)
        this.getProductCategory(this.$route.params.ulidCategory)
        this.categoryName= this.$route.params.categoryName
    }
}
</script>

<style scoped>
@media screen and (max-width: 992px) {
  .sidebarCategories {
      display: none !important
  }
}
@media screen and (min-width: 992px) {
  .sidebarCategories2 {
      display: none !important
  }
}

.bg-gradient-success{
  background:linear-gradient(to right, #dfc23a, #ffb159)
}

</style>